import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import Coring from "../components/Coring"
// ...GatsbyImageSharpFluid
import styled from "styled-components"
import Navbar from "../components/Navbar"
import Experience from "../components/Experience"
import Qualifications from "../components/Qualifications"
import Footer from "../components/Footer"
import Hero2 from "../components/Hero2"
import TopImgAbout from "../assets/about-image-1_d.jpg"
import TopImgAbout2 from "../assets/about-image-1_m.jpg"
import SEO from "../components/SEO"
import Image from "gatsby-image"





const About = ({fluid}) => {

const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "about-image-1_d.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)


  return(
    
      <Layout>
        <SEO title="About Us |" description="this is the about us page"/>
        <ContImg>
          <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
        </ContImg>
        <Hero2/>
        <Experience />
        <Qualifications/>
    </Layout>

    
  )
}

const ContImg = styled.div`
width: 100%;
  height: 600px;
  // background-image: url(${TopImgAbout});
  // background-size: 100% 100%;

  @media screen and (max-width: 1110px) {
  height: 400px;

}

  @media screen and (max-width: 700px) {
  height: 350px;
  // background-image: url(${TopImgAbout2});
}
`



export default About
