import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components";
import Home from "../assets/home-image-2.jpg";
import Hand1 from "../assets/home-image-3.jpg";
import Hand2 from "../assets/home-image-4.jpg";
import Hand3 from "../assets/home-image-5.jpg";
import MiddleImg from "../assets/about-image-2.jpg"
import Accordion from "./Accordion"
import ImageSlider from "./ImageSlider"
import {SliderData} from "./SliderData"
import TestimonialList from "../components/TestimonialList";
import Image from "gatsby-image"



const Hero2 = ({fluid}) => {

const data = useStaticQuery(graphql`
  query {
  imageSharp(fluid: {originalName: {eq: "about-image-2.jpg"}}) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
  `)

  return (
    <>
    <DivTop>
      <DivInner>
        <Container1>
          <Content> Our mission is to consistently deliver the best job site services at both the highest quality and most competitive pricing. </Content>
           <MobileImg> 
              <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
           </MobileImg>
        </Container1>
         
        <Container2>
           <Image fluid={fluid ? fluid : data.imageSharp.fluid} style={{
             width: "100%",
             height: "100%"
           }}/>
         
        </Container2>

      </DivInner>
    </DivTop>
 
   </>
  )
}



const MobileImg = styled.div`
display: none;

@media screen and (max-width: 700px) {
display: block;
height: 250px;
margin-left: 16px;
margin-right: 16px;
// background-image: url(${MiddleImg});
// background-size: 100% 100%;
}

`

const Content = styled.h3`
font-weight: 700;
line-height: 48px;
font-size: 40px;
font-family: "GT America Bold";
color: #fff;
padding-top: 56px;
padding-left: 90px;
padding-right: 90px;

@media screen and (max-width: 1250px) {
padding-left: 30px;
padding-right: 30px;

}

@media screen and (max-width: 1160px) {
padding-left: 30px;
padding-right: 30px;

}

@media screen and (max-width: 1110px) {
line-height: 32px;
font-size: 24px;
padding-top: 30px;
padding-left: 32px;
padding-right: 32px;

}

@media screen and (max-width: 735px) {
line-height: 32px;
font-size: 24px;
padding-bottom: 16px;
text-align: left;
padding-left: 16px;
padding-right: 16px;
padding-top: 10px;

}

@media screen and (max-width: 700px) {
line-height: 32px;
font-size: 24px;
padding-bottom: 16px;
text-align: left;
padding-left: 16px;
padding-right: 16px;
padding-top: 16px;

}
`
const Container1 = styled.div`
width: 50%;
height: 100%;
background-color: #2287B2;
margin-left: 64px;

@media screen and (max-width: 1110px) {
margin-left: 24px;

}

@media screen and (max-width: 700px) {
width: 100%;
margin-left: 0px;
height: 250px;


}

@media screen and (max-width: 490px) {
height: 290px;
}

@media screen and (max-width: 400px) {
height: 320px;
}

@media screen and (max-width: 353px) {
height: 350px;
}

@media screen and (max-width: 28px) {
height: 390px;
}


`
const Container2 = styled.div`
width: 50%;
height: 100%;
margin-right: 64px;
// background-image: url(${MiddleImg});
// background-size: 100% 100%;

@media screen and (max-width: 1110px) {
margin-right: 24px;
}

@media screen and (max-width: 700px) {
display: none;

}
`

const DivInner = styled.div`
height: 424px;
width: 100%;
position: absolute;
top: 420px;

display: flex;

@media screen and (max-width: 1110px) {
top: 320px;
height: 232px;

}
`

const DivTop = styled.div`
height: 300px;
width: 100%;
background-color: #0D6DA3;

@media screen and (max-width: 1110px) {
height: 180px;
}

@media screen and (max-width: 700px) {
height: 100px;
}
`
 




export default Hero2
